<template>
  <div>
    <!-- 最上方流程欄位 -->
    <div class="check-step bg-white d-flex shadow-sm">
      <div class="item">
        <span>01.</span>
        <span>{{ $t("CART.STEP1") }}</span>
      </div>
      <div class="item active">
        <span>02.</span>
        <span>{{ $t("CART.STEP2") }}</span>
      </div>
      <div class="item">
        <span>03.</span>
        <span>{{ $t("CART.STEP3") }}</span>
      </div>
    </div>
    <!-- 取餐方式TAB、表單輸入框 -->
    <div class="check-way checkout-select">
      <br />
      <!-- <h3 class="text-center mt-2 mb-3">取餐方式/時間</h3> -->
      <div role="tabpanel">
        <label style="padding: 0 10px">
          請選擇取餐方式：
          <span style="color: red">(必填)</span>
        </label>
        <!-- List group -->
        <div class="check-way-list list-group" id="checkWayList" role="tablist">
          <!-- <a
            :class="
              'list-group-item list-group-item-action ' + state.tabForHereActive
            "
            data-toggle="list"
            role="tab"
            @click="
              state.useCheckoutType = 'forHere';
              handleTabClick();
            "
            v-if="shop_setting.for_here === 1"
          >店內點餐</a>
          <a
            :class="
              'list-group-item list-group-item-action ' + state.tabToGoActive
            "
            data-toggle="list"
            role="tab"
            @click="
              state.useCheckoutType = 'toGo';
              handleTabClick();
              state.tabToGo = true;
            "
            v-if="shop_setting.to_go === 1"
          >到店自取</a>
          <a
            :class="
              'list-group-item list-group-item-action ' + state.tabOrderInActive
            "
            data-toggle="list"
            role="tab"
            @click="
              state.useCheckoutType = 'orderIn';
              handleTabClick();
            "
            v-if="shop_setting.order_in === 1"
          >店家外送</a>-->
          <a
            class="list-group-item list-group-item-action"
            :class="{'active': state.useCheckoutType === 'order'}"
            data-toggle="list"
            role="tab"
            @click="checkUseCheckoutType('order')"
            v-if="shop_setting.order.status === 1"
          >
          現場點餐</a>
          <a
            class="list-group-item list-group-item-action"
            :class="{'active': state.useCheckoutType === 'here'}"
            data-toggle="list"
            role="tab"
            @click="checkUseCheckoutType('here')"
            v-if="shop_setting.here.status === 1"
          >預約內用</a>
          <a
            class="list-group-item list-group-item-action"
            :class="{'active': state.useCheckoutType === 'togo'}"
            data-toggle="list"
            role="tab"
            @click="checkUseCheckoutType('togo')"
            v-if="shop_setting.togo.status === 1"
          >到店自取</a>
          <a
            class="list-group-item list-group-item-action"
            :class="{'active': state.useCheckoutType === 'delivery'}"
            data-toggle="list"
            role="tab"
            @click="checkUseCheckoutType('delivery')"
            v-if="shop_setting.delivery.status === 1"
          >店家外送</a>
        </div>

        <!-- Tab panes -->
        <div class="check-way-content tab-content">
          <!-- 現場點餐 -->
          <div
            class="tab-pane py-2 active"
            id="inside"
            role="tabpanel"
            v-if="state.useCheckoutType === 'order'"
          >
            <!-- 備註 -->
            <div class="form-group container">
              <p class="mb-0 small text-muted">店家備註:</p>
              <div class="d-block my-2 border-bottom" v-html="state.useTypeSetting.description"></div>
            </div>
            <!-- 大人人數 -->
            <div class="form-group container">
              <label for="insidePeopleAdult">大人人數：</label>
              <div class="row">
                <div class="col-2" v-for="a in 4" :key="a">
                  <button
                    type="button"
                    class="btn btn-block"
                    :class="{ active: state.useAdult === a }"
                    @click="selectAdultPeople(a)"
                  >{{a}}</button>
                </div>
                <div class="col dropdown">
                  <button
                    class="btn btn-block dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="{ active: state.useAdult > 4 }"
                  >{{dropdownTitle.adult}}</button>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <button
                      class="dropdown-item"
                      type="button"
                      v-for="n in 16"
                      :key="n"
                      @click="selectAdultPeople(n+4)"
                    >{{n + 4}}</button>
                  </div>
                </div>
                <!-- <div class="col">
                  <select class="custom-select" id="insidePeopleChild" v-model="state.useChild">
                    <option selected>選擇孩童人數</option>
                    <option value="0">0位孩童</option>
                    <option :value="n" v-for="n in 10" :key="n">{{ n }}位孩童</option>
                  </select>
                </div>-->
              </div>
            </div>
            <!-- 小孩人數 -->
            <div class="form-group container mt-3">
              <div class="d-flex">
                <label for="insidePeopleAdult">孩童人數：</label>
                <a
                  href="#"
                  class="ml-auto removeChild"
                  v-show="state.useChild > 0"
                  @click.prevent="selectChildPeople(0)"
                >移除人數</a>
              </div>
              <div class="row">
                <div class="col-2" v-for="c in 4" :key="c">
                  <button
                    type="button"
                    class="btn btn-block position-relative"
                    :class="{ active: state.useChild === c }"
                    @click="selectChildPeople(c)"
                  >{{c}}</button>
                </div>
                <div class="col dropdown">
                  <button
                    class="btn btn-block dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="{ active: state.useChild > 4 }"
                  >{{dropdownTitle.child}}</button>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <button
                      class="dropdown-item"
                      type="button"
                      v-for="n in 16"
                      :key="n"
                      @click="selectChildPeople(n + 4)"
                    >{{n + 4}}</button>
                  </div>
                </div>
                <!-- <div class="col">
                  <select class="custom-select" id="insidePeopleChild" v-model="state.useChild">
                    <option selected>選擇孩童人數</option>
                    <option value="0">0位孩童</option>
                    <option :value="n" v-for="n in 10" :key="n">{{ n }}位孩童</option>
                  </select>
                </div>-->
              </div>
            </div>
            <!-- 桌號 -->
            <div class="form-group container" v-if="tablesOptions && tablesOptions.length > 0">
              <label for="insideBuyerName">
                桌號：
                <span style="color: red">(必填)</span>
              </label>
                <cascader-tables
                  :table1Id="state.useBuyerTable1Id"
                  :table2Id="state.useBuyerTable2Id"
                  @changeTables="changeTables"
                ></cascader-tables>
            </div>
            <div class="form-group container">
              <label for="insideBuyerName">
                姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerName"
                    type="text"
                    class="form-control"
                    id="insideBuyerName"
                    placeholder="請輸入姓名..."
                  />
                </div>
              </div>
              <label for="insideBuyerPhone">
                電話：
                <span style="color: red">(必填，現場請憑電話號碼取餐)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerPhone"
                    type="tel"
                    class="form-control"
                    id="insideBuyerPhone"
                    placeholder="請輸入電話..."
                  />
                </div>
              </div>
              <label for="insideComment">備註：</label>
              <div class="row row-cols-1">
                <div class="col">
                  <textarea
                    v-model="state.useComment"
                    class="form-control"
                    id="insideComment"
                    rows="3"
                    name="insideComment"
                    placeholder="請輸入備註事項..."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- 預約內用 -->
          <div
            class="tab-pane py-2 active"
            id="inside"
            role="tabpanel"
            v-if="state.useCheckoutType === 'here'"
          >
            <!-- 備註 -->
            <div class="form-group container">
              <p class="mb-0 small text-muted">店家備註:</p>
              <div class="d-block my-2 border-bottom" v-html="state.useTypeSetting.description"></div>
            </div>
            <!-- 大人人數 -->
            <div class="form-group container">
              <label for="insidePeopleAdult">大人人數：</label>
              <div class="row">
                <div class="col-2" v-for="a in 4" :key="a">
                  <button
                    type="button"
                    class="btn btn-block"
                    :class="{ active: state.useAdult === a }"
                    @click="selectAdultPeople(a)"
                  >{{a}}</button>
                </div>
                <div class="col dropdown">
                  <button
                    class="btn btn-block dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="{ active: state.useAdult > 4 }"
                  >{{dropdownTitle.adult}}</button>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <button
                      class="dropdown-item"
                      type="button"
                      v-for="n in 16"
                      :key="n"
                      @click="selectAdultPeople(n+4)"
                    >{{n + 4}}</button>
                  </div>
                </div>
                <!-- <div class="col">
                  <select class="custom-select" id="insidePeopleChild" v-model="state.useChild">
                    <option selected>選擇孩童人數</option>
                    <option value="0">0位孩童</option>
                    <option :value="n" v-for="n in 10" :key="n">{{ n }}位孩童</option>
                  </select>
                </div>-->
              </div>
            </div>
            <!-- 小孩人數 -->
            <div class="form-group container mt-3">
              <div class="d-flex">
                <label for="insidePeopleAdult">孩童人數：</label>
                <a
                  href="#"
                  class="ml-auto removeChild"
                  v-show="state.useChild > 0"
                  @click.prevent="selectChildPeople(0)"
                >移除人數</a>
              </div>
              <div class="row">
                <div class="col-2" v-for="c in 4" :key="c">
                  <button
                    type="button"
                    class="btn btn-block position-relative"
                    :class="{ active: state.useChild === c }"
                    @click="selectChildPeople(c)"
                  >{{c}}</button>
                </div>
                <div class="col dropdown">
                  <button
                    class="btn btn-block dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="{ active: state.useChild > 4 }"
                  >{{dropdownTitle.child}}</button>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <button
                      class="dropdown-item"
                      type="button"
                      v-for="n in 16"
                      :key="n"
                      @click="selectChildPeople(n + 4)"
                    >{{n + 4}}</button>
                  </div>
                </div>
                <!-- <div class="col">
                  <select class="custom-select" id="insidePeopleChild" v-model="state.useChild">
                    <option selected>選擇孩童人數</option>
                    <option value="0">0位孩童</option>
                    <option :value="n" v-for="n in 10" :key="n">{{ n }}位孩童</option>
                  </select>
                </div>-->
              </div>
            </div>
            <div class="form-group container">
              <label>用餐時間：</label>
              <div class="row row-cols-2">
                <div class="col">
                  <button
                    class="btn btn-block btn-select"
                    id="dateBtn-forhere"
                    @click="state.pickDatedrawer = true"
                  >
                    <template v-if="state.usePickDate == 0">請選擇日期</template>
                    <template v-else>{{ state.usePickDate }}</template>
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-block btn-select"
                    id="timeBtn-forhere"
                    @click="state.pickTimedrawer = true"
                  >
                    <template v-if="state.useMealTime == null">請選擇時間</template>
                    <template v-else>{{ state.useMealTime }}</template>
                  </button>
                </div>
              </div>
              <small class="text-danger d-block my-2">*我們會盡量安排您預約的時間，實際用餐時間以現場安排為主。</small>
            </div>
            <div class="form-group container">
              <label for="insideBuyerName">
                姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerName"
                    type="text"
                    class="form-control"
                    id="insideBuyerName"
                    placeholder="請輸入姓名..."
                  />
                </div>
              </div>
              <label for="insideBuyerPhone">
                電話：
                <span style="color: red">(必填，到店用餐請報電話號碼)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerPhone"
                    type="tel"
                    class="form-control"
                    id="insideBuyerPhone"
                    placeholder="請輸入電話..."
                  />
                </div>
              </div>
              <label for="insideComment">備註：</label>
              <div class="row row-cols-1">
                <div class="col">
                  <textarea
                    v-model="state.useComment"
                    class="form-control"
                    id="insideComment"
                    rows="3"
                    name="insideComment"
                    placeholder="請輸入備註事項..."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- 自取 -->
          <div
            class="tab-pane py-2 active"
            id="takeout"
            role="tabpanel"
            v-if="state.useCheckoutType === 'togo'"
          >
            <!-- 備註 -->
            <div class="form-group container">
              <p class="mb-0 small text-muted">店家備註:</p>
              <div class="d-block my-2 border-bottom" v-html="state.useTypeSetting.description"></div>
            </div>
            <div class="btn-group-toggle container reserve-time" data-toggle="buttons">
              <label>取餐時間：</label>
              <div class="row row-cols-2">
                <div class="col">
                  <button
                    class="btn btn-block btn-select"
                    id="dateBtn-togo"
                    @click="state.pickDatedrawer = true"
                  >
                    <template v-if="state.usePickDate == 0">請選擇日期</template>
                    <template v-else>{{ state.usePickDate }}</template>
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-block btn-select"
                    id="timeBtn-togo"
                    @click="state.pickTimedrawer = true"
                  >
                    <template v-if="state.useMealTime == null">請選擇時間</template>
                    <template v-else>{{ state.useMealTime }}</template>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group container">
              <label for="takeOutBuyerName">
                姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerName"
                    type="text"
                    class="form-control"
                    id="takeOutBuyerName"
                    placeholder="請輸入姓名..."
                  />
                </div>
              </div>
              <label for="takeOutBuyerPhone">
                電話：
                <span style="color: red">(必填，現場請憑電話號碼取餐)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerPhone"
                    type="phone"
                    class="form-control"
                    id="takeOutBuyerPhone"
                    placeholder="請輸入電話..."
                  />
                </div>
              </div>
              <label for="takeOutComment">備註：</label>
              <div class="row row-cols-1">
                <div class="col">
                  <textarea
                    v-model="state.useComment"
                    class="form-control"
                    id="takeOutComment"
                    rows="3"
                    placeholder="請輸入備註事項..."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- 外送 -->
          <div
            class="tab-pane py-2 active"
            id="deliver"
            role="tabpanel"
            v-if="state.useCheckoutType === 'delivery'"
          >
            <!-- 備註 -->
            <div class="form-group container">
              <p class="mb-0 small text-muted">店家備註:</p>
              <div class="d-block my-2 border-bottom" v-html="state.useTypeSetting.description"></div>
            </div>
            <div class="btn-group-toggle container reserve-time" data-toggle="buttons">
              <small class="text-muted d-block my-2">
                <!--              外送距離3公里內，且滿$1000元即可免費外送-->
              </small>

              <label>取餐時間：</label>
              <div class="row row-cols-2">
                <div class="col">
                  <button
                    class="btn btn-block btn-select"
                    id="dateBtn-deliver"
                    @click="state.pickDatedrawer = true"
                  >
                    <template v-if="state.usePickDate == 0">請選擇日期</template>
                    <template v-else>{{ state.usePickDate }}</template>
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-block btn-select"
                    id="timeBtn-deliver"
                    @click="state.pickTimedrawer = true"
                  >
                    <template v-if="state.useMealTime == null">請選擇時間</template>
                    <template v-else>{{ state.useMealTime }}</template>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-group container">
              <label for="receiverName">
                訂購人姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <!-- 原本綁定的model  state.useReceiverName -->
                  <input
                    v-model="state.useBuyerName"
                    type="text"
                    class="form-control"
                    id="receiverName"
                    placeholder="請輸入收件人..."
                  />
                </div>
              </div>
              <label for="receiverPhone">
                訂購人電話：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <!-- 原本綁定的model  state.useReceiverPhone -->
                  <input
                    v-model="state.useBuyerPhone"
                    type="tel"
                    class="form-control"
                    id="receiverPhone"
                    placeholder="請輸入收件人電話..."
                  />
                </div>
              </div>
              <label for="deliverDate">
                訂購人地址：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row mb-2">
                <cascader-cites
                  :cityId="state.useBuyerCityId"
                  :cityareaId="state.useBuyerCityareaId"
                  type="buyer"
                  @change="setProfileSelect"
                ></cascader-cites>
              </div>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useBuyerAddress"
                    type="text"
                    class="form-control"
                    id="deliverAdd"
                    placeholder="請輸入訂購人地址..."
                  />
                </div>
              </div>
              <hr />
            </div>

            <div class="form-group container">
              <label for="receiverNameDelivery">取餐地址：</label>
              <div class="row row-cols-2">
                <div class="col">
                  <label for="options0" class="btn btn-block" style="border: 1px solid #dee2e6">
                    <input
                      id="options0"
                      type="radio"
                      name="options"
                      value="same"
                      :checked="!state.showReceiver"
                      @click="state.showReceiver = false"
                    />
                    同上
                  </label>
                </div>
                <div class="col">
                  <label for="options1" class="btn btn-block" style="border: 1px solid #dee2e6">
                    <input
                      id="options1"
                      type="radio"
                      name="options"
                      value="other"
                      :checked="state.showReceiver"
                      @click="state.showReceiver = true"
                    />
                    其他地址
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group container" v-show="state.showReceiver">
              <!-- -->
              <label for="receiverNameDelivery">
                取餐人姓名：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useReceiverName"
                    type="text"
                    class="form-control"
                    id="receiverNameDelivery"
                    placeholder="請輸入取餐人姓名"
                  />
                </div>
              </div>
              <label for="receiverPhoneDelivery">
                取餐人電話：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useReceiverPhone"
                    type="tel"
                    class="form-control"
                    id="receiverPhoneDelivery"
                    placeholder="請輸入取餐人電話"
                  />
                </div>
              </div>
              <label for="receiverAddressDelivery">
                取餐人地址：
                <span style="color: red">(必填)</span>
              </label>
              <div class="row mb-2">
                <cascader-cites
                  :cityId="state.useReceiverCityId"
                  :cityareaId="state.useReceiverCityareaId"
                  type="receiver"
                  @change="setProfileSelect"
                ></cascader-cites>
                <!-- @change="setRecevierProfileSelect" -->
              </div>
              <div class="row">
                <div class="col">
                  <input
                    v-model="state.useReceiverAddress"
                    type="text"
                    class="form-control"
                    id="receiverAddressDelivery"
                    placeholder="請輸入取餐人地址..."
                  />
                </div>
              </div>
            </div>
            <div class="form-group container">
              <small
                class="text-danger d-block my-2"
              >外送訂單是否成立，以店家電話聯繫為主，下單成功並非代表訂單成立。店家會因為人力調度或忙碌情況不接受訂單，請知悉！</small>
            </div>
            <div class="form-group container">
              <label for="deliverComment">備註：</label>
              <div class="row row-cols-1">
                <div class="col">
                  <textarea
                    v-model="state.useComment"
                    class="form-control"
                    id="deliverComment"
                    rows="3"
                    placeholder="請輸入備註事項..."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- <cart-coupon class="container" v-model="couponValue" :currentPrice="Number(cartTotal)" /> -->
          <div class="form-group container">
            <div
              class="row text-right font-weight-bold ponit-block"
              style="font-size: medium; margin-bottom:68px"
            >
              <!-- <point v-model="pointValue" :couponValue="couponValue" /> -->

              <div class="col-12">
                <span>小計：</span>
                <span class="text-primary">{{ cartQuantity }}</span>
                <span>{{ $t("CART.TOTAL.GOODS_UNIT") }}</span>
                <span class="ml-3">金額：</span>
                <span class="text-main big">
                  ${{
                  formatMoney(
                  parseInt(cartTotal, 10) -
                  pointValue +
                  getCouponDiscountOrExtra(couponValue, Number(cartTotal))
                  )
                  }}
                </span>
                <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
              </div>
              <div class="col-12">
                <span>服務費：</span>
                <span class="text-main big">${{formatMoney(feeCount)}}</span>
                <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
              </div>
              <div class="col-12">
                <span>{{ $t("CART.TOTAL.GOODS") }}：</span>
                <span class="text-main big">${{formatMoney(parseInt(cartTotal, 10) + feeCount)}}</span>
                <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 回上一頁、確認下單按鈕 -->
    <nav class="checkout-menu">
      <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
        <div class="col px-3">
          <a
            href="/carts"
            class="btn bg-dark btn-block shadow text-white my-2"
            id="optionClose"
          >{{ $t("CHECKOUT.BUTTON.BACK") }}</a>
        </div>
        <div class="col px-3">
          <button
            type="button"
            class="btn btn-block btn-main shadow text-white my-2"
            @click="onCheckout"
            :disabled="isCheckouting"
          >{{ $t("CART.BUTTON.NEXT") }}</button>
        </div>
      </div>
    </nav>
    <!-- 取餐時間、日期折疊抽屜 -->
    <div class="check-way">
      <div class="reserve-time">
        <el-drawer
          title="請選擇日期"
          v-model="state.pickDatedrawer"
          direction="btt"
          :with-header="false"
          custom-class="date-time-panel"
        >
          <div class="modal-dialog container">
            <div class="modal-content">
              <p class="text-center text-secondary mt-3 mb-1">請選擇日期</p>
              <div class="date-panel row py-2">
                <div class="col" :key="key" v-for="(value, key) in pickDate">
                  <label
                    :class="[
                      'btn btn-block btn-outline-secondary',
                      { active: key == state.radioDate },
                    ]"
                  >
                    <input type="radio" name="options" v-model="state.radioDate" :value="key" />
                    {{ key }}
                  </label>
                </div>
              </div>
              <el-button
                @click="
                  onChangePickDateTimer(state.radioDate);
                  state.pickDatedrawer = false;
                "
                type="primary"
                class="btn btn-block btn-primary text-white mb-3"
              >選擇</el-button>
            </div>
          </div>
        </el-drawer>
        <el-drawer
          title="請選擇時間"
          v-model="state.pickTimedrawer"
          direction="btt"
          :with-header="false"
          custom-class="date-time-panel"
          size="70%"
        >
          <div class="modal-dialog container">
            <div class="modal-content">
              <p class="text-center text-secondary mt-3 mb-1">請選擇時間</p>
              <div class="time-panel row" style="max-height: calc(100vh - 143px); overflow-y: auto">
                <div class="col" v-for="(time, key) in state.optionsPickTime" :key="key">
                  <div v-if="time.value != '-' && time.value !== 0">
                    <label
                      :class="[
                        'btn btn-block btn-outline-secondary ' +
                          handleHourDisabled(key, 'class'),
                        { active: time.value == state.radioTime },
                      ]"
                      :for="'options0_' + key"
                    >
                      <input
                        v-model="state.radioTime"
                        type="radio"
                        name="options"
                        :id="'options0_' + key"
                        :value="time.value"
                        :disabled="handleHourDisabled(key)"
                      />
                      {{ time.text }}
                    </label>
                  </div>
                </div>
              </div>
              <el-button
                @click="
                  handleOptionClick(state.radioTime);
                  state.pickTimedrawer = false;
                "
                type="primary"
                class="btn btn-block btn-primary text-white mb-3"
              >選擇</el-button>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
//Add By Bryant
import md5 from "js-md5";
//import utf8 from 'utf8';
//=====
import CascaderCites from "@/components/CascaderCites";
import CascaderTables from "@/components/CascaderTables";
import { computed, reactive, watch, inject, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns-tz";
import {
  getPickDateTimeObject,
  getPickTimeOptions,
  formatMoney,
} from "@/utils/tools";
import $ from "jquery";
import Swal from "sweetalert2";
import Schema from "async-validator";
// import Point from "@/components/Point.vue";
// import CartCoupon from "@/components/cart-coupon/index.vue";
import { getCouponDiscountOrExtra } from "@/components/cart-coupon/utils";
import moment from "moment";
import storage from "@u/storage";
storage.config = { type: "session" };

export default {
  name: "CheckoutView",
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  components: {
    CascaderCites,
    // Point,
    // CartCoupon,
    CascaderTables,
  },
  setup(props, { emit }) {
    $(".dropdown-toggle").dropdown();
    const shop_name = inject("shop_name");
    const shop_setting = inject("shop_setting");
    // console.log(shop_setting);
    const shop_open_news = inject("shop_open_news"); // 店家營業時間
    const shop_closed = inject("shop_closed");
    const shop_is_invoice = inject("shop_is_invoice"); // 是否開發票
    const router = useRouter();
    const storageCheckoutInfo = storage.getItem("checkout_info");
    const storageAddressInfo = storage.getItem("address_info");
    // console.log(storageAddressInfo);

    // 取得取餐方式的預設
    const getShopSettingDefault = () => {
      let shopSetting = ["order", "here", "togo", "delivery"];
      let arr = [];
      shopSetting.forEach((item) => {
        if (shop_setting.value[item].status === 1) {
          arr.push(item);
        }
      });
      return arr[0];
    };

    const state = reactive({
      useCheckoutType: storageCheckoutInfo
        ? storageCheckoutInfo.type
        : getShopSettingDefault(),
      useAdult: storageCheckoutInfo ? storageCheckoutInfo.people.adult : 1,
      useChild: storageCheckoutInfo ? storageCheckoutInfo.people.child : 0,
      usePickDate: null, // format(new Date(), 'yyyy-MM-dd'),
      useMealTime: null,
      useComment: null,
      useBuyerAddress: null,
      useBuyerCityId: 0,
      useBuyerCityareaId: 0,
      useBuyerName: null,
      useBuyerPhone: null,
      useBuyerTable1Id: storageCheckoutInfo
        ? storageCheckoutInfo.table1_Id
        : null,
      useBuyerTable2Id: storageCheckoutInfo
        ? storageCheckoutInfo.table2_Id
        : null,
      useBuyerInvoiceType: shop_is_invoice.value == "true" ? 1 : 0,
      useBuyerInvoiceCarrierId: null,
      useBuyerInvoiceNumber: null,
      useBuyerInvoiceNpoBan: null,
      useReceiverName: null,
      useReceiverPhone: null,
      useReceiverAddress: null,
      useReceiverCityId: 0,
      useReceiverCityareaId: 0,
      businessDisabled: [],
      optionsPickTime: [],
      pickDatedrawer: false,
      pickTimedrawer: false,
      radioDate: "",
      radioTime: "",
      showReceiver: false,
      useTypeSetting: storageCheckoutInfo
        ? storageCheckoutInfo.useTypeSetting
        : shop_setting.value[getShopSettingDefault()],
    });
    const store = useStore();
    const { t } = useI18n();
    const pointValue = ref(0);
    const couponValue = ref({});

    //無資料就回到產品列表頁面
    store.dispatch("carts/isEmptyCarts");
    const isEmptyCarts = computed(() => {
      return store.getters["carts/isEmptyCarts"];
    });

    //發票選項
    const invoiceOptions = [
      {
        value: 1,
        label: "電子發票-個人",
      },
      {
        value: 2,
        label: "電子發票-公司",
      },
      {
        value: 3,
        label: "手機條碼載具",
      },
      {
        value: 4,
        label: "愛心碼",
      },
    ];

    //Add By Bryant

    const str2hex = (str) => {
      let hexstr = unescape(encodeURIComponent(str))
        .split("")
        .map(function (v) {
          return v.charCodeAt(0).toString(16).padStart(2, "0");
        })
        .join("");

      return hexstr;
    };

    const do_CloudPrint = (printData) => {
      //先取得商品清單
      // console.log("cartList.value: ", cartList.value);
      let str_for_print_hex = "";

      if (cartList.value.length > 0) {
        var str_for_print = "";

        cartList.value.forEach((ListItem) => {
          //console.log('ListItem: ', ListItem);
          //console.log('ListItem.product.title: ', ListItem.product.title);

          str_for_print += ListItem.product.title + "\n";

          if (ListItem.addition_products) {
            str_for_print += "\t" + ListItem.addition_products + "\n";
          } else {
            //console.log('No ListItem.product.addition_products, Why!?');
          }

          str_for_print +=
            "\t" +
            "$" +
            ListItem.product.price +
            " X " +
            ListItem.quantity +
            "\n";
        });

        //return false;

        let print_head = str2hex("餐點明細" + "\n");
        print_head += str2hex("------------------------" + "\n");
        print_head = "1B21301B6131" + print_head + "0A";
        print_head = print_head + "1B6130" + "0A";

        //let username = unescape(encodeURIComponent('取貨姓名: '  + state.useBuyerName + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //let userphone = unescape(encodeURIComponent('取貨電話: '  + state.useBuyerPhone + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //let lll = unescape(encodeURIComponent('------------------------' + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //let Desc = username + userphone + lll;

        let d = new Date();

        let currentDTStr =
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1).toString().padStart(2, 0) +
          "-" +
          d.getDate().toString().padStart(2, 0) +
          " " +
          d.getHours().toString().padStart(2, 0) +
          ":" +
          d.getMinutes().toString().padStart(2, 0) +
          ":" +
          d.getSeconds().toString().padStart(2, 0);

        let orderInfo = "";

        //console.log('currentDTStr: ', currentDTStr);

        //return false;

        switch (state.useCheckoutType) {
          case "forHere":
            {
              orderInfo += str2hex("內用" + "\n");
            }
            break;
          case "toGo":
            {
              orderInfo += str2hex("到店自取" + "\n");
            }
            break;
          case "orderIn":
            {
              orderInfo += str2hex("店家外送" + "\n");
            }
            break;
        }

        //orderInfo += str2hex('訂單時間: ' + currentDTStr + "\n");
        orderInfo += str2hex("訂單時間: ");
        orderInfo += "1B2110" + str2hex(currentDTStr) + "1B2130" + "0A";
        orderInfo += str2hex("顧客姓名: " + state.useBuyerName + "\n");
        orderInfo += str2hex("顧客電話: " + state.useBuyerPhone + "\n");
        orderInfo += str2hex("\n" + "------------------------" + "\n");

        let print_tail = "\n" + "------------------------" + "\n";
        //print_tail += '小計: ' + cartQuantity.value + '項' + "\n";
        print_tail += "金額: " + parseInt(cartTotal.value) + "元" + "\n";

        //str_for_print_hex = unescape(encodeURIComponent(str_for_print)).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //str_for_print_hex = unescape(encodeURIComponent(str_for_print + print_tail)).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        str_for_print_hex = str2hex(str_for_print + print_tail);
        //console.log('str_for_print_hex: ', str_for_print_hex);
        str_for_print_hex = "1B2130" + str_for_print_hex; //把字寬高加大
        //console.log('str_for_print_hex: ', str_for_print_hex);
        //str_for_print_hex = print_head + str_for_print_hex;
        //str_for_print_hex = print_head + Desc + str_for_print_hex;
        str_for_print_hex = print_head + orderInfo + str_for_print_hex;

        str_for_print_hex = str_for_print_hex + "0A0A0A0A0A1D5631"; //空5行後裁紙
      }

      var currenTS = Math.floor(Date.now() / 1000);
      //console.log('currenTS: ', currenTS);

      const URL = "https://openapi.sunmi.com/v1/printer/pushContent";
      var queryString = "app_id=09978ba6ff2b43488a53e0bbafd7acd2";
      queryString += "&msn=N404212K00159";
      queryString += "&orderCnt=1";
      //queryString += '&orderData=e6b8ace8a9a6e59586e593810ae696b0e5b8bde5ad900a'; //Just For Test
      queryString += "&orderData=" + str_for_print_hex; //Just For Test
      queryString += "&orderType=1";
      queryString += "&pushId=" + currenTS;
      queryString += "&timestamp=" + currenTS;
      //queryString += '&voice=Line 訂單列印終';
      queryString += "&voice=啟秉皇上,來訂單啦";
      queryString += "&voiceCnt=1";

      var qS2 = queryString + "375f21532d594feab41ed30c16092704";
      //      console.log('qS2: ', qS2);

      var md5qS2 = md5(qS2);
      md5qS2 = md5qS2.toUpperCase();
      //      console.log('md5qS2: ', md5qS2);

      queryString += "&sign=" + md5qS2;

      //Tmp Use
      //return false;

      var xhr = new XMLHttpRequest();
      xhr.open("POST", URL, true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send(queryString);
      //=====
    };
    // 取得今天日期
    function getTodayDate() {
      // const week = moment().isoWeekday();
      // // console.log(week)

      // const shopFirstOpenTimeObj = shop_open_news.value.find(item => {
      //   return item.status === 1
      // })
      // const dayDiff = shopFirstOpenTimeObj.day - week
      // const shopFirstOpenTime = moment().add(dayDiff,'days').format('YYYY-MM-DD');
      // const openDateWeek = moment(shopFirstOpenTime).weekday()
      // let week_ch;
      // switch (openDateWeek) {
      //   case 0:
      //     week_ch = "日";
      //     break;
      //   case 1:
      //     week_ch = "一";
      //     break;
      //   case 2:
      //     week_ch = "二";
      //     break;
      //   case 3:
      //     week_ch = "三";
      //     break;
      //   case 4:
      //     week_ch = "四";
      //     break;
      //   case 5:
      //     week_ch = "五";
      //     break;
      //   case 6:
      //     week_ch = "六";
      //     break;
      // }

      // const openDate = moment(shopFirstOpenTime).format(`YYYY-MM-DD (${week_ch})`);

      let openDate = Object.keys(pickDate)[0];
      return openDate;
    }

    //=====
    //一開始就要取資料
    store.dispatch("carts/getCarts"); //取購物車
    store.dispatch("member/getProfile").then(() => {
      store.dispatch("member/getDefaultAddress");
      state.usePickDate = storageCheckoutInfo
        ? storageCheckoutInfo.pick_date
        : getTodayDate();
      state.useBuyerCityId = storageAddressInfo
        ? storageAddressInfo.buyer_city_id
        : defaultAddress.value.city_id;
      state.useBuyerCityareaId = storageAddressInfo
        ? storageAddressInfo.buyer_cityarea_id
        : defaultAddress.value.cityarea_id;
      state.useBuyerAddress = storageAddressInfo
        ? storageAddressInfo.buyer_address
        : defaultAddress.value.address;
      state.useBuyerName = storageCheckoutInfo
        ? storageCheckoutInfo.buyer_name
        : profile.value.name;
      state.useBuyerPhone = storageCheckoutInfo
        ? storageCheckoutInfo.buyer_phone
        : profile.value.mobile_phone;

      // state.useBuyerTable1Id = storageCheckoutInfo == null ? storageCheckoutInfo.table1_Id : 0
      // state.useBuyerTable2Id = storageCheckoutInfo == null ? storageCheckoutInfo.table2_Id : 0
      state.useBuyerInvoiceCarrierId = profile.value.invoice_carrier;
      state.useBuyerInvoiceNpoBan = profile.value.npo_ban;
      state.useBuyerInvoiceNumber = profile.value.vat_number;
      state.useReceiverName = storageCheckoutInfo
        ? storageCheckoutInfo.receiver_name
        : "";
      state.useReceiverPhone = storageCheckoutInfo
        ? storageCheckoutInfo.receiver_phone
        : "";
      state.useReceiverCityId = storageCheckoutInfo
        ? storageCheckoutInfo.receiver_city_id
        : "";
      state.useReceiverCityareaId = storageCheckoutInfo
        ? storageCheckoutInfo.receiver_cityarea_id
        : "";
      state.useReceiverAddress = storageCheckoutInfo
        ? storageCheckoutInfo.receiver_address
        : "";
    });
    store.dispatch("orders/getBusinessCount");
    //computed: getter
    const isLoading = computed(() => store.getters["carts/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    const cartList = computed(() => store.getters["carts/cartList"]); // 取得購物清單
    const isCheckouting = computed(() => store.getters["carts/isCheckouting"]); // 取得是否可以結帳
    const checkoutData = computed(() => store.getters["carts/checkoutData"]);
    const profile = computed(() => store.getters["member/profile"]); // 取得使用者資訊
    const defaultAddress = computed(
      // 取得地址資訊
      () => store.getters["member/defaultAddress"]
    );
    const businessCount = computed(() => store.getters["orders/businessCount"]); //取得營業時間
    const cartTotal = computed(() => store.getters["carts/cartTotal"]); // 取得購物費用總計
    const cartQuantity = computed(() => store.getters["carts/cartQuantity"]); // 取得購物車數量
    // console.log(cartQuantity);
    const dropdownTitle = ref({
      adult: "更多",
      child: "更多",
    });
    store.dispatch("shop/getTablesCascader");
    const tablesOptions = computed(() => store.getters["shop/tables"]); // 取得桌號
    // console.log(tablesOptions);
    const feeSetting = computed(() => store.getters["shop/fee"]); // 取得服務費
    // console.log(feeSetting);

    //methods
    // 內用、自取、外送tab切換
    // const handleTabClick = () => {
    //   const key = state.useCheckoutType;
    //   const tab = { forHere: false, toGo: false, orderIn: false };
    //   const tabActive = { forHere: "", toGo: "", orderIn: "" };
    //   tab[key] = true;
    //   tabActive[key] = "active";
    //   state.tabForHere = tab.forHere;
    //   state.tabToGo = tab.toGo;
    //   state.tabOrderIn = tab.orderIn;
    //   state.tabForHereActive = tabActive.forHere;
    //   state.tabToGoActive = tabActive.toGo;
    //   state.tabOrderInActive = tabActive.orderIn;
    // };
    // 選擇內用時間
    const handleOptionClick = (val) => {
      state.useMealTime = val;
    };
    // 判斷未營業的日期
    const handleHourDisabled = (key, type = "key") => {
      if (type == "class") return state.businessDisabled[key] ? "disabled" : "";
      else return state.businessDisabled[key];
    };
    // 取得縣市代碼
    const setProfileSelect = (value, type) => {
      // console.log(value, type);
      if (type === "buyer") {
        state.useBuyerCityId = value[0];
        state.useBuyerCityareaId = value[1];
      } else {
        state.useReceiverCityId = value[0];
        state.useReceiverCityareaId = value[1];
      }
    };
    // 選擇取餐方式
    const checkUseCheckoutType = (type) => {
      // console.log(shop_setting.value[type]);
      state.useCheckoutType = type;
      state.useTypeSetting = shop_setting.value[type];
      state.useTypeSetting.description = stringNewline(
        state.useTypeSetting.description
      );
      shopOpen();
      if (type === "order") {
        state.usePickDate = getTodayDate();
        state.radioDate = getTodayDate();
      }
    };
    // 字串換行
    const stringNewline = (description) => {
      if (description !== null && description.includes("\n")) {
        return description.replace(/\n/g, "<br>");
      } else {
        return description;
      }
    };
    state.useTypeSetting.description = stringNewline(
      state.useTypeSetting.description
    );

    // 下單
    const onCheckout = () => {
      if (validateCheckout() === false) return false;

      //Add By Bryant

      //do_CloudPrint();

      //return false;
      //=====

      // store
      //   .dispatch("carts/checkoutOrder", {
      //     pickDate: state.usePickDate,
      //     type: state.useCheckoutType,
      //     meal_at: state.useMealTime,
      //     people: { adult: state.useAdult, child: state.useChild },
      //     member_coupon_id: couponValue.value.id,
      //     point: pointValue.value,
      //     comment: state.useComment,
      //     receiver_city_id: state.useCityId,
      //     receiver_cityarea_id: state.useCityareaId,
      //     receiver_address: state.useAddress,
      //     receiver_name: state.useReceiverName,
      //     receiver_phone: state.useReceiverPhone,
      //     buyer_name: state.useBuyerName,
      //     buyer_phone: state.useBuyerPhone,
      //     invoice: {
      //       type: state.useBuyerInvoiceType,
      //       vat_number: state.useBuyerInvoiceNumber,
      //       carrier_id: state.useBuyerInvoiceCarrierId,
      //       npo_ban: state.useBuyerInvoiceNpoBan,
      //     },
      //   })
      //   .then()
      //   .catch(() => {
      //     onAlert("error");
      //   });

      // 將訂單存到storage
      store
        .dispatch("carts/saveAddressInfo", {
          buyer_city_id: state.useBuyerCityId,
          buyer_cityarea_id: state.useBuyerCityareaId,
          buyer_address: state.useBuyerAddress,
        })

      // 將訂單存到storage
      store
        .dispatch("carts/saveCheckoutInfo", {
          type: state.useCheckoutType,
          pick_date: state.usePickDate,
          // pick_time: state.useMealTime,
          meal_at: state.useMealTime,
          people: { adult: state.useAdult, child: state.useChild },
          comment: state.useComment,
          receiver_city_id: state.useReceiverCityId,
          receiver_cityarea_id: state.useReceiverCityareaId,
          receiver_address: state.useReceiverAddress,
          receiver_name: state.useReceiverName,
          receiver_phone: state.useReceiverPhone,
          buyer_name: state.useBuyerName,
          buyer_phone: state.useBuyerPhone,
          buyer_city_id: state.useBuyerCityId,
          buyer_cityarea_id: state.useBuyerCityareaId,
          buyer_address: state.useBuyerAddress,
          table1_Id: state.useBuyerTable1Id,
          table2_Id: state.useBuyerTable2Id,
          invoice: {
            type: state.useBuyerInvoiceType,
            vat_number: state.useBuyerInvoiceNumber,
            carrier_id: state.useBuyerInvoiceCarrierId,
            npo_ban: state.useBuyerInvoiceNpoBan,
          },
          useTypeSetting: state.useTypeSetting,
        })
        .then((res) => {
          router.push("/checkout_confirm");
        })
        .catch((error) => {
          console.log(error);
          onAlert("error");
        });
      //Add By Bryant

      //Marked at 2022/03/22
      //do_CloudPrint();

      //=====
    };
    // 驗證
    const validateCheckout = () => {
      let error_msg = "";
      let schema = {};
      schema["useMealTime"] = {
        required: true,
        message: "請選擇「" + "用餐/取餐」時間",
      };
      schema["useBuyerName"] = {
        required: true,
        type: "string",
        message: "訂購人：請填入資料，不可空白",
      };
      if (tablesOptions.value && state.useCheckoutType === "order") {
        if (tablesOptions.value.length > 0) {
          schema["useBuyerTable1Id"] = {
            required: true,
            type: "number",
            message: "請選擇區域，不可空白",
          };
          // console.log(tablesOptions)
          if (state.useBuyerTable1Id) {
            let value = tablesOptions.value.find(
              (item) => item.value === state.useBuyerTable1Id
            );
            if (value.children.length > 0) {
              schema["useBuyerTable2Id"] = {
                required: true,
                type: "number",
                message: "請選擇桌號，不可空白",
              };
            }
          }
        }
      }
      schema["useBuyerPhone"] = {
        required: true,
        type: "string",
        min: 8,
        max: 10,
        pattern: /^[0-9]+$/,
        message: "訂購人電話：格式不符(8-10碼數字）",
      };
      if (state.useCheckoutType === "delivery") {
        schema["useBuyerCityId"] = {
          required: true,
          type: "number",
          message: "訂購人城市：請選擇城市",
        };
        schema["useBuyerCityareaId"] = {
          required: true,
          type: "number",
          message: "訂購人區域：請選擇區域",
        };
        schema["useBuyerAddress"] = {
          required: true,
          type: "string",
          message: "訂購人地址：請填入資料，不可空白",
        };
        schema["useReceiverName"] = {
          required: true,
          type: "string",
          message: "取餐人：請填入資料，不可空白",
        };
        schema["useReceiverPhone"] = {
          required: true,
          type: "string",
          min: 8,
          max: 10,
          pattern: /^[0-9]+$/,
          message: "取餐人電話：格式不符(8-10碼數字）",
        };
        if (state.showReceiver) {
          schema["useReceiverCityId"] = {
            required: true,
            type: "number",
            message: "取餐人城市：請選擇城市",
          };
          schema["useReceiverCityareaId"] = {
            required: true,
            type: "number",
            message: "取餐人區域：請選擇區域",
          };
          schema["useReceiverAddress"] = {
            required: true,
            type: "string",
            message: "取餐人地址：請填入資料，不可空白",
          };
        } else {
          state.useReceiverCityareaId = state.useBuyerCityareaId;
          state.useReceiverCityId = state.useBuyerCityId;
          state.useReceiverAddress = state.useBuyerAddress;
          state.useReceiverPhone = state.useBuyerPhone;
          state.useReceiverName = state.useBuyerName;
        }
      }

      const validator = new Schema(schema);
      validator.validate(state, (errors, fields) => {
        if (errors) {
          errors.forEach((error) => (error_msg += error.message + "<br />"));
        }
      });
      if (error_msg !== "") {
        onAlert("error", error_msg);
        return false;
      } else {
        return true;
      }
    };
    const onAlert = (type, error_msg = null) => {
      let title = type === "success" ? t("ALERT.SUCCESS") : t("ALERT.ERROR");
      error_msg =
        type !== "error"
          ? ""
          : "<br /><span style='color:red'>" + error_msg + "</span>";
      Swal.fire({
        title: title,
        html: "確認下單失敗" + error_msg,
        icon: type,
        showConfirmButton: true,
        confirmButtonColor: "#fd7e14",
        // confirmButtonClass: "btn btn-block btn-main shadow text-white my-2",
        confirmButtonText: "關閉",
        timer: 5000,
      });
    };

    //預設資料
    // if (shop_setting.value.for_here === 1) {
    //   state.useCheckoutType = "forHere";
    //   handleTabClick();
    // } else if (shop_setting.value.to_go === 1) {
    //   state.useCheckoutType = "toGo";
    //   handleTabClick();
    // } else if (shop_setting.value.order_in === 1) {
    //   state.useCheckoutType = "orderIn";
    //   handleTabClick();
    // }

    const onChangePickDateTimer = (pick_date) => {
      state.usePickDate = pick_date;
      // state.optionsPickTime = getPickTimeOptions(pickDate, pick_date);
      const pickTimeArr = getPickTimeOptions(pickDate, pick_date);
      let optionsRemainder = null;
      if (pickTimeArr.length > 0) {
        const first_minute = parseInt(pickTimeArr[0].value.split(":")[1]);
        optionsRemainder = first_minute / 15;
        for (let i = 0; i < optionsRemainder; i++) {
          let obj = {
            value: 0,
          };
          pickTimeArr.unshift(obj);
        }
      }
      state.optionsPickTime = pickTimeArr;
      if (state.optionsPickTime.length > 1) {
        // state.useMealTime = storageCheckoutInfo
        //   ? storageCheckoutInfo.meal_at
        //   : state.optionsPickTime[optionsRemainder].value; // 預設從有時間的開始
        state.useMealTime = state.optionsPickTime[optionsRemainder].value;
      } else {
        state.usePickDate = "-";
        state.useMealTime = "-";
      }
      state.radioTime = state.useMealTime;
    };

    // handleTabClick();
    //營業時間檢查
    let pickDate = null;
    const shopOpen = () => {
      const weeks = shop_open_news.value.filter(function (week) {
        return week.status === 1;
      });
      pickDate = getPickDateTimeObject(
        weeks,
        // shop_setting.value.preparation_min,
        state.useTypeSetting ? state.useTypeSetting.pre_order_after_minute : 0,
        shop_closed.value.status,
        shop_closed.value.min
      );
      state.usePickDate = storageCheckoutInfo
        ? storageCheckoutInfo.pick_date
        : Object.keys(pickDate)[0];
      state.radioDate = state.usePickDate;
      onChangePickDateTimer(state.usePickDate);
    };
    shopOpen();

    //選擇大人人數
    const selectAdultPeople = (p) => {
      state.useAdult = p;
      dropdownTitle.value.adult = p > 4 ? p : "更多";
    };
    //選擇孩童人數
    const selectChildPeople = (p) => {
      state.useChild = p;
      dropdownTitle.value.child = p > 4 ? p : "更多";
    };
    // 選擇桌號
    const changeTables = (value) => {
      // console.log(value);
      state.useBuyerTable1Id = value[0];
      state.useBuyerTable2Id = value[1];
    };

    // 服務費計算
    const feeCount = computed(() => {
      // console.log(feeSetting.value);
      let fee = 0;
      if (
        state.useCheckoutType === "order" ||
        state.useCheckoutType === "here"
      ) {
        if (feeSetting.value.type === 0) {
          // 關閉服務費
          fee = 0;
        } else {
          fee = Math.round((cartTotal.value * feeSetting.value.percent) / 100);
        }
      } else {
        fee = 0;
      }
      return fee;
    });

    return {
      // data
      shop_name,
      shop_setting,
      shop_is_invoice,
      state,
      pickDate,
      invoiceOptions,
      pointValue,
      cartTotal,
      formatMoney,
      couponValue,
      getCouponDiscountOrExtra,
      dropdownTitle,

      // computed
      isEmptyCarts,
      myIsLoading,
      cartList,
      isCheckouting,
      checkoutData,
      defaultAddress,
      businessCount,
      tablesOptions,
      cartQuantity,
      feeCount,

      // methods
      // handleTabClick,
      handleOptionClick,
      handleHourDisabled,
      setProfileSelect,
      onCheckout,
      format,
      onAlert,
      onChangePickDateTimer,
      //Add By Bryant
      str2hex,
      do_CloudPrint,
      //=====
      selectAdultPeople,
      selectChildPeople,
      changeTables,
      checkUseCheckoutType,
      stringNewline,
    };
  },
  watch: {
    // checkoutData: function () {
    //   if (this.checkoutData !== null && !this.isCheckouting) {
    //     if (this.checkoutData.is_created === true) {
    //       // this.$router.push("/checkout-finish");
    //     } else {
    //       let msg = "";
    //       if (this.checkoutData.error.code === 12005)
    //         msg = "購物車無商品，請重新確認。";
    //       this.onAlert("error", msg);
    //     }
    //   }
    // },
    isEmptyCarts: function (newVal, oldVal) {
      if (newVal === true) {
        if (this.storage.getItem("category_id") > 0)
          this.$router.push(
            "/home/" + this.storage.getItem("category_id", { type: "session" })
          );
        else this.$router.push("/home");
      }
    },
    businessCount: function (newVal, oldVal) {
      const defaultDate = this.state.usePickDate;
      let _start_time = null;
      let _end_time = null;
      let _open_time = null;
      let _close_time = null;
      const businessArr = this.businessCount.filter((data) => {
        return data.date === defaultDate;
      });
      this.state.optionsPickTime.forEach((timer, index) => {
        _start_time = null;
        _end_time = null;
        businessArr.forEach((data) => {
          _open_time = new Date(defaultDate + "T" + data.open).getTime();
          _close_time = new Date(defaultDate + "T" + data.close).getTime();
          if (timer.value !== "-") {
            _start_time = new Date(defaultDate + "T" + timer.value).getTime();
            if (_start_time >= _open_time && _start_time <= _close_time)
              this.state.businessDisabled[index] =
                data.count >= this.shop_setting.limit &&
                this.shop_setting.limit !== 0
                  ? true
                  : false;
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$primary-color: #fa821b;
.el-cascader {
  width: 100%;
}
.check-way .check-way-list .list-group-item {
  cursor: pointer;
}
.check-way {
  button.active {
    background-color: $primary-color;
    color: #fff;
  }
}
.checkout-select {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    .col,
    .col-2,
    .col-3,
    .col-6 {
      padding: 0 8px;
    }
  }
}
.removeChild {
  color: #3488fc;
}
.dropdown-toggle::after {
  margin-left: 6px;
}
.dropdown-menu {
  min-width: calc(100% - 16px);
  height: 160px;
  overflow-y: scroll;
  padding: 0;
}
.el-select {
  width: 100%;
}
.point-block {
  padding-bottom: 30px;

  & > div {
    margin-bottom: 12px;
  }
}
</style>
