<template>
  <div class="row">
    <!-- <el-cascader
      v-model="tableIds"
      :props="tablesProps"
      :options="tablesOptions"
      @change="handleChange"
      placeholder="請選擇桌號"
    />-->
    <div style="padding:0 8px" :class="{'col-6': tableChildrens.length > 0,'col-12': tableChildrens.length <= 0}">
      <div class="dropdown">
        <button
          class="btn btn-block dropdown-toggle text-left"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >{{areaName}}</button>
        <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
          <button
            class="dropdown-item"
            v-for="item in tablesOptions"
            :key="item.value"
            @click.prevent="checkAreaNum(item.value)"
          >{{item.label}}</button>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="tableChildrens.length > 0">
      <div class="dropdown">
        <button
          class="btn btn-block dropdown-toggle text-left"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >{{tableName}}</button>
        <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
          <button
            class="dropdown-item"
            v-for="item in tableChildrens"
            :key="item.value"
            @click.prevent="checkTableNum(item.value)"
          >{{item.label}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";

export default {
  props: {
    table1Id: {
      type: Number,
    },
    table2Id: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const tablesOptions = computed(() => store.getters["shop/tables"]); // 取得桌號
    const tableChildrens = ref([]);
    const areaName = ref("請選擇區域");
    const tableName = ref("");
    const tempTableIds = ref([]);

    const checkAreaNum = (areaValue) => {
      if (areaValue) {
        store.dispatch("shop/getTablesNum", areaValue);
        const tableItem = computed(() => store.getters["shop/tableItem"]);
        tableChildrens.value = [...tableItem.value.children];
        areaName.value = tableItem.value.label;
        tempTableIds.value[0] = tableItem.value.value;
        if (tableItem.value.children.length === 0) tempTableIds.value[1] = null;
        tableName.value = "請選擇桌號";
      }
    };

    const checkTableNum = (tableValue) => {
      if (tableValue) {
        const tableArr = tableChildrens.value.find(
          (item) => item.value === tableValue
        );
        tableName.value = tableArr.label;
        tempTableIds.value[1] = tableArr.value;
      }
    };
    store.dispatch("shop/getTablesCascader").then(() => {
      checkAreaNum(props.table1Id);
      checkTableNum(props.table2Id);
    });

    watch(tempTableIds.value, (n, o) => {
      emit("changeTables", n);
    });
    return {
      // data
      tablesOptions,
      tableChildrens,
      areaName,
      tableName,

      // methods
      checkAreaNum,
      checkTableNum,
    };
  },
};
</script>

<style scoped>
.col-6 {
  padding: 0 8px;
}
</style>
